import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';

export function PageNotFound() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageNotFound.meta.title',
            defaultMessage: '404 — Page Not Found',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 10, textAlign: 'center' }}>
          <Typography variant="h1">
            <FormattedMessage
              id="components.PageNotFound.header"
              defaultMessage="Page Not Found"
            />
          </Typography>

          <Box sx={{ mb: 2 }} />

          <Box component="img" src="/404.svg" sx={{ maxWidth: '100%' }} />
        </Container>
      </DefaultLayout>
    </>
  );
}
