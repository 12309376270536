import { defineMessages } from 'react-intl';

export const salutations = defineMessages({
  'salutations.miss': {
    id: 'salutations.miss',
    defaultMessage: 'Miss.',
  },
  'salutations.attn': {
    id: 'salutations.attn',
    defaultMessage: 'Attn.',
  },
  'salutations.dr': {
    id: 'salutations.dr',
    defaultMessage: 'Dr.',
  },
  'salutations.mr': {
    id: 'salutations.mr',
    defaultMessage: 'Mr.',
  },
  'salutations.mrs': {
    id: 'salutations.mrs',
    defaultMessage: 'Mrs.',
  },
  'salutations.ms': {
    id: 'salutations.ms',
    defaultMessage: 'Ms.',
  },
});
