import { defineMessages } from 'react-intl';

export const contactUsReason = defineMessages({
  'components.PageContactUs.form.field.reason.technicalQuestion': {
    id: 'components.PageContactUs.form.field.reason.technicalQuestion',
    defaultMessage: 'Technical Issue',
  },
  'components.PageContactUs.form.field.reason.productQuestion': {
    id: 'components.PageContactUs.form.field.reason.productQuestion',
    defaultMessage: 'Product Question',
  },
  'components.PageContactUs.form.field.reason.programSpecificQuestion': {
    id: 'components.PageContactUs.form.field.reason.programSpecificQuestion',
    defaultMessage: 'Program Specific Question',
  },
  'components.PageContactUs.form.field.reason.cardDownload': {
    id: 'components.PageContactUs.form.field.reason.cardDownload',
    defaultMessage: 'Card Download',
  },
  'components.PageContactUs.form.field.reason.generalInquiry': {
    id: 'components.PageContactUs.form.field.reason.generalInquiry',
    defaultMessage: 'General Inquiry',
  },
  'components.PageContactUs.form.field.reason.other': {
    id: 'components.PageContactUs.form.field.reason.other',
    defaultMessage: 'Other',
  },
});
