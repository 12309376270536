import { httpClient } from '../httpClient';
import { DateTime, URLString } from '../types';

type Base64Image = string;

interface ProductInfo {
  encId: string;
  name: string;
  frName: string;
  logo: {
    encId: string;
    name: string;
    content: Base64Image;
  };
  frLogo: {
    encId: string;
    name: string;
    content: Base64Image;
  };
  provinces: string[];
  genericName: string;
  frGenericName: string;
  promotionalText: string;
  frPromotionalText: string;
}

interface ReferralPartner {
  encId: string;
  name: string;
  frName: string;
  description: string;
  frDescription: string;
  url: URLString;
  slug: string;
  createDate: DateTime;
  modifiedDate: DateTime;
  createdBy?: {
    encId: string;
    lastName: string;
    firstName: string;
    email: string;
    isActive: boolean;
  };
  modifiedBy?: {
    encId: string;
    lastName: string;
    firstName: string;
    email: string;
    isActive: boolean;
  };
  logo?: {
    encId: string;
    name: string;
    url: URLString;
  };
  frLogo?: {
    encId: string;
    name: string;
    url: URLString;
  };
  cardBackGound?: {
    encId: string;
    name: string;
    url: URLString;
  };
  frCardBackGound?: {
    encId: string;
    name: string;
    url: URLString;
  };
  referalPartnerBackGround?: {
    encId: string;
    name: string;
    url: URLString;
  };
  frReferalPartnerBackGround?: {
    encId: string;
    name: string;
    url: URLString;
  };
  programLogo?: {
    encId: string;
    name: string;
    url: URLString;
  };
  programFrLogo?: {
    encId: string;
    name: string;
    url: URLString;
  };
  card?: {
    encId: string;
    name: string;
    createDate: string;
    modifiedDate: string;
    cardType: string;
    tagLine: string;
    frTagLine: string;
    certificateTextColor: string;
    description: string;
    frDescription: string;
    pdfOptions: string;
  };
  customPdfFile?: Base64Image;
  certificatePosition: 'Top' | 'None' | 'Bottom' | 'Left';
  status: 'active' | 'inactive';
}

export function getReferralPartnerBySlug(
  slug: string,
): Promise<ReferralPartner> {
  return httpClient
    .get<void, ReferralPartner>(`/slugs/${slug}/ReferralPartners`)
    .then((data) => {
      // TODO: Should be done on BE
      const isActive = data.status === 'active';
      if (!isActive) {
        throw new Error('Referral Partner does not exists');
      }
      return data;
    });
}
