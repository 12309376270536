import React, { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { styled } from 'styled-components';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { PromoCardPopup } from '../../components/PromoCardPopup';
import { HomeNewsBlock } from '../../components/HomeNewsBlock';
import { PharmacyLocator } from '../../components/PharmacyLocator';
import { ROUTES } from '../../router';
import { ProvinceSelector } from '../../components/ProvinceSelector';
import { HomeProgramSupporters } from '../../components/HomeProgramSupporters';
import { HomeFeaturedBrands } from '../../components/HomeFeaturedBrands';
import { HomeAboutBlock } from '../../components/HomeAboutBlock';
import { RxHelpCard } from '../../components/RxHelpCard';
import { Language, l10n } from '../../Language';

const ProvinceDiv = styled.div`
  display: inline-grid;

  .MuiButton-root.Mui-disabled {
    background-color: rgba(243, 150, 0, 0.12);
  }

  .MuiButton-root {
    background-color: rgba(243, 150, 0);
  }

  .MuiInputBase-root {
    background-color: white;
  }
`;

const buttonStyle = {
  height: 'fit-content',
  marginTop: '1rem',
  borderRadius: '100px',
  padding: '16px 42px',
  color: '#ffffff',
  textDecoration: 'none',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '1.25em',
};

export function PageHome() {
  const intl = useIntl();
  const { lang } = useParams();
  const [locale] = useAppLocale();

  const [province, setProvince] = useState('');

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageHome.meta.title',
            defaultMessage: 'Home',
          })}
        </title>
      </Helmet>

      <PromoCardPopup />

      <DefaultLayout>
        <Box
          minHeight={{ lg: '680px' }}
          paddingBottom={{ xs: '50px' }}
          paddingLeft={{ sm: '50px' }}
          paddingRight={{ sm: '50px' }}
          sx={{
            py: 4,
            backgroundImage: 'url(/Homepage-banner-rxhelp-2000.jpg)',
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            backgroundSize: 'cover',
          }}
        >
          <Container>
            <Box
              display={{ xs: 'flex', sm: 'inline-grid' }}
              marginRight={{ xs: '-16px' }}
              marginLeft={{ xs: '-16px', sm: '-32px' }}
              sx={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                marginTop: '-32px',
                marginBottom: '-32px',
              }}
            >
              <Box sx={{ padding: '32px' }}>
                <Typography
                  variant="h1"
                  fontSize={{ xs: '2.25em', sm: '3.25em' }}
                  style={{
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  <FormattedMessage
                    id="components.PageHome.header"
                    defaultMessage="RxHelpONE"
                  />
                </Typography>
                <Typography
                  paragraph
                  style={{ fontSize: '1.5em', color: 'white' }}
                >
                  <FormattedMessage
                    id="components.PageHome.subtitle"
                    defaultMessage="Your Medicine. Your Choice."
                  />
                </Typography>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={30}>
                  <ProvinceDiv
                    style={{
                      display: 'inline-grid',
                    }}
                  >
                    <ProvinceSelector
                      label={
                        <FormattedMessage
                          id="components.PageHome.select.province"
                          defaultMessage="Select Province"
                        />
                      }
                      size="small"
                      isOnlyActive
                      value={province}
                      language={(lang as Language) ?? 'en'}
                      onChange={(event) => setProvince(event.target.value)}
                      style={{
                        height: '3rem',
                      }}
                    />
                    <Button
                      style={buttonStyle}
                      variant="contained"
                      disabled={!province}
                      href={`${generatePath(ROUTES.WIZARD, {
                        lang: lang ?? 'en',
                      })}?province=${province}`}
                    >
                      <FormattedMessage
                        id="components.PageHome.getCard"
                        defaultMessage="Get Your Free Card"
                      />
                    </Button>
                    <Box sx={{ mt: 2, maxWidth: { xs: '100%' } }}>
                      <RxHelpCard
                        cert=""
                        group=""
                        layout="Bottom"
                        imgUrl={l10n(
                          {
                            en: '/program-card-rxhelpone.png',
                            fr: '/program-card-rxhelpone-fr.png',
                          },
                          locale,
                        )}
                        certificateTextColor="white"
                        noLabels
                      />
                    </Box>
                  </ProvinceDiv>
                </Stack>
              </Box>
            </Box>
          </Container>
        </Box>

        <HomeFeaturedBrands province={province} />

        <HomeNewsBlock />

        <PharmacyLocator />

        <HomeAboutBlock />

        <HomeProgramSupporters />
      </DefaultLayout>
    </>
  );
}
