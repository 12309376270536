import { httpClient } from '../httpClient';
import { DateTime, HomePagePopup, PositiveInteger } from '../types';

interface GetHomePagePopupsResponse {
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Array<HomePagePopup>;
}

interface GetHomePagePopupsParams {
  orderBy: `${keyof HomePagePopup}:${'asc' | 'desc'}`;
}
interface GetHomePagePopupsInternalParams extends GetHomePagePopupsParams {
  status: 'active' | 'inactive';
  start: DateTime;
  end: DateTime;
  page: 1; // Fixed by spec
  pageSize: 10; // Fixed by spec
}

export function getHomePagePopups(params: GetHomePagePopupsParams) {
  const now = new Date();
  const startDate = new Date(now);
  startDate.setHours(0, 0, 0, 0);
  const start = startDate.toLocaleString();

  const endDate = new Date(now);
  endDate.setHours(0, 0, 0, 0);
  const end = endDate.toLocaleString();

  console.log('Popup Start:', start);
  console.log('Popup End:', end);

  return httpClient
    .get<GetHomePagePopupsInternalParams, GetHomePagePopupsResponse>(
      '/HomePagePopups/GetPromoCards',
      {
        status: 'active',
        start,
        end,
        page: 1,
        pageSize: 10,
        ...params,
      },
    )
    .then((data) => {
      // TODO: Should be done on BE
      return {
        ...data,
        items: data.items.filter((item) => item.status === 'active'),
      };
    });
}
