import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { ThemeFontSize, makeTheme } from './theme';

const FontSizeContext = createContext<
  [ThemeFontSize, React.Dispatch<React.SetStateAction<ThemeFontSize>>]
>([
  ThemeFontSize.Medium,
  () => {
    // noop
  },
]);

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function AppThemeProvider({ children }: ThemeProviderProps) {
  const state = useState(ThemeFontSize.Medium);
  const [size] = state;

  const theme = useMemo(() => makeTheme(size), [size]);

  return (
    <FontSizeContext.Provider value={state}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </FontSizeContext.Provider>
  );
}

export function useThemeFontSize() {
  return useContext(FontSizeContext);
}
