const convertHTMLToString = (html: any): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return (doc.body && doc.body.textContent) || '';
};

const convertHTMLToText = (html: string) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export {
  convertHTMLToString,
  convertHTMLToText,
};
