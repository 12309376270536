import { httpClient } from '../httpClient';

interface IDeactivationActivityResponsePayload {
  createdBy: {
    email: string;
  };
  createdDate: string;
  reason: string;
}

enum Status {
  NOT_SET = 'NOT_SET',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  DEPLETED = 'DEPLETED',
}

type CardType = 'RxHelpOne' | 'Specialty' | 'RxHelpOneSub';

interface ICardPdfRequestPayload {
  cardInformationDetails: string;
  cardMessageDetails: string;
  encId: string;
  modifiedBy: {
    email: string;
  };
  modifiedDate: string;
  language: string;
  patientInstructionsDetails: string;
  pharmacyInformationDetails: string;
}

interface ILogosResponsePayload {
  encId: string;
  name: string;
  url: string;
  frEncId: string;
  frName: string;
  frUrl: string;
}

export interface ICardRequestPayload {
  deactivationActivity: IDeactivationActivityResponsePayload;
  frName: string;
  description: string;
  frDescription: string;
  id: string;
  isDeletable: boolean;
  modifiedDate: string;
  name: string;
  status: Status | null;
  frStatus: Status | null;
  cardType: CardType;
  programType: CardType;
  pdfOptions: string;
  pdfSettings: string;
  certificatePosition: string;
  certificatePoolType: string;
  cardPdfs: ICardPdfRequestPayload[];
  certificatePools: any[];
  links?: {
    frUrl?: string;
    frUrlText?: string;
    url?: string;
    urlText?: string;
  };
  enLogo: ILogosResponsePayload;
  frLogo: ILogosResponsePayload;
  pdfLogo: ILogosResponsePayload;
  frPdfLogo: ILogosResponsePayload;
  pdfCard: ILogosResponsePayload;
  promotionDescription: string;
  frPromotionDescription: string;
  legalConsent: string;
  frLegalConsent: string;
  legalDescription: string;
  frLegalDescription: string;
  termsAndConditions: string;
  frTermsAndConditions: string;
  isProgramUrlRequired: boolean;
  emailBody: string;
  frEmailBody: string;
  messageOnCard?: string;
  frMessageOnCard?: string;
  informationAboutTheCard?: string;
  frInformationAboutTheCard?: string;
  instructionsForThePharmacy?: string;
  frInstructionsForThePharmacy?: string;
  instructionsToPatient?: string;
  frInstructionsToPatient?: string;
  termsOfUsage?: string;
  frTermsOfUsage?: string;
  startDate: string;
  endDate: string;
  productsCount: number;
  urlRequired: boolean;
}

export enum CardTypes {
  RxHelpOne = 1,
  Specialty = 2,
  RxHelpOneSub = 3,
}

export function getCardByCardType(
  cardType: CardTypes,
): Promise<ICardRequestPayload> {
  return httpClient.get<CardTypes, ICardRequestPayload>(`/cards/${cardType}`);
}
