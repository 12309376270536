import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Pagination,
  Slide,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
  ButtonBase,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';
import { Controller, Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.css';
import { toast } from 'react-toastify';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import {
  ALL_VARIANTS,
  AlphabetSelector,
} from '../../components/AlphabetSelector';
import { getApiError } from '../../lib/httpClient';
import { wizardSteps } from './wizardSteps.translations';
import { BasketItem } from './BasketItem';
import { ListItem } from './ListItem';
import {
  SearchMedicationsParams,
  searchMedications,
} from '../../lib/api/searchMedications';
import { CardTypes, getCardByCardType } from '../../lib/api/getProgramByType';
import { Language, l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { PharmacyLocator } from '../../components/PharmacyLocator';
import { generateCard } from '../../lib/api/generateCard';
import { Loading } from '../../components/Loading';
import { LocationStateProduct } from '../../components/HomeFeaturedBrands';
import { Medication, CardInfo as Card } from '../../lib/types';
import { convertHTMLToString } from '../../app/.shared/utils/convertUtil';
import { CardInfo } from './CardInfo';
import { CardLayout } from '../../components/RxHelpCard';
import { CertificateGenerationPoint } from '../../CertificateGenerationPoint';
import {
  LoyaltyObjectRequestData,
  createPass,
  createPassApple,
} from '../../lib/api/walletApi';
import { env } from '../../app/.shared/settings/env';

const { GOOGLE_API_ISSUER_ID } = env;

const MarkerDiv = styled.div`
  ol {
    list-style-position: inside;
    margin-top: 2px;
  }
  li.ql-indent-1 {
    counter-increment: alpha;
  }
  li.ql-indent-1::marker {
    counter-increment: alpha;
    content: counter(alpha, lower-alpha) '. ';
    padding-left: 4.5em;
  }
  li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  p {
    margin: 1px;
  }
`;

enum WizardSteps {
  SelectProvince = 0,
  SelectMedications = 1,
  Consent = 2,
  Finish = 3,
}
const STEPS = [
  WizardSteps.SelectProvince,
  WizardSteps.SelectMedications,
  WizardSteps.Consent,
  WizardSteps.Finish,
] as const;

const pageSize = 16;
const DEFAULT_LOGO = '';

function getDrugId(drug: Medication): string {
  return `${drug.encId}`;
}

function filterUniqueCards(
  medications: Medication[],
  encCardId: string | null,
): Promise<Medication['cards']> {
  const filteredResult: Card[] = [];
  const subPrograms: Card[] = [];

  if (medications && medications.length > 0) {
    if (encCardId) {
      medications.forEach((med) => {
        const cardIndex = med.cards.findIndex(
          (item) => item.encId === encCardId,
        );

        if (cardIndex > -1 && filteredResult.length <= 0) {
          filteredResult.push(med.cards[cardIndex]);
        }
      });
    }

    if (filteredResult.length > 0) {
      return Promise.all(filteredResult);
    }

    medications.forEach((med) => {
      med.cards.forEach((card) => {
        const cardIndex = subPrograms.findIndex(
          (item) => item.encId === card.encId,
        );

        if (cardIndex < 0 && card.programType === 'RxHelpOneSub') {
          subPrograms.push(card);
        }
      });
    });

    if (subPrograms && subPrograms.length > 0) {
      subPrograms.forEach((item) => {
        const isEveryMedicationHasSubProgram = medications.every((m) => {
          return m.cards.findIndex((c) => c.encId === item.encId) > -1;
        });

        if (isEveryMedicationHasSubProgram) {
          filteredResult.push(item);
        }
      });
    }

    if (filteredResult.length > 0) {
      if (filteredResult.length > 1) {
        let filteredCard: Card | null = null;
        filteredResult.forEach((item) => {
          medications.forEach((m) => {
            if (
              m.cards.findIndex(
                (c) => c.encId === item.encId && c.productsCount > 1,
              ) > -1 &&
              filteredCard === null
            ) {
              filteredCard = item;
            }
          });
        });

        if (filteredCard !== null) {
          const cardsFilter = [filteredCard];
          return Promise.all(cardsFilter);
        }

        const cards = [filteredResult[0]];
        return Promise.all(cards);
      }
      return Promise.all(filteredResult);
    }

    medications.forEach((item) => {
      const i = item.cards.findIndex((x) => x.programType === 'RxHelpOne');
      if (i > -1 && filteredResult.length <= 0) {
        filteredResult.push(item.cards[i]);
      }
    });
  }

  if (filteredResult && filteredResult.length > 0) {
    return Promise.all(filteredResult);
  }

  const cardTypeFunction = getCardByCardType(CardTypes.RxHelpOne).then(
    (card) => {
      if (card !== null && card !== undefined) {
        filteredResult.push({
          encId: card.id,
          createDate: new Date().toDateString(),
          modifiedDate: new Date().toDateString(),
          programType: card.cardType,
          legalConsent: card.legalConsent || '',
          frLegalConsent: card.frLegalConsent
            ? String(card.frLegalConsent)
            : '',
          legalDescription: card.legalDescription
            ? String(card.legalDescription)
            : '',
          frLegalDescription: card.frLegalDescription
            ? String(card.frLegalDescription)
            : '',
          termsAndConditions: card.termsAndConditions
            ? String(card.termsAndConditions)
            : '',
          frTermsAndConditions: card.frTermsAndConditions
            ? String(card.frTermsAndConditions)
            : '',
          programName: card.name || '',
          frProgramName: card.frName || '',
          programDescription: card.description || '',
          frProgramDescription: card.frDescription || '',
          logo: card.enLogo
            ? {
                encId: card.enLogo.encId,
                content: card.enLogo.url,
                name: card.enLogo.name,
              }
            : {
                encId: '',
                content: '',
                name: '',
              },
          frLogo: card.frLogo
            ? {
                encId: card.frLogo.encId,
                content: card.frLogo.url,
                name: card.frLogo.name,
              }
            : {
                encId: '',
                content: '',
                name: '',
              },
          pdfLogo: card.pdfLogo
            ? {
                encId: card.pdfLogo.encId,
                content: card.pdfLogo.url,
                name: card.pdfLogo.name,
              }
            : {
                encId: '',
                content: '',
                name: '',
              },
          frPdfLogo: card.frPdfLogo
            ? {
                encId: card.frPdfLogo.encId,
                content: card.frPdfLogo.url,
                name: card.frPdfLogo.name,
              }
            : {
                encId: '',
                content: '',
                name: '',
              },
          url: card.links?.url ?? '',
          frUrl: card.links?.frUrl ?? '',
          urlText: card.links?.urlText ?? '',
          frUrlText: card.links?.frUrlText ?? '',
          productsCount: card.productsCount,
          urlRequired: card.urlRequired,
        });

        return filteredResult;
      }
      return filteredResult;
    },
  );

  return cardTypeFunction;
}

export function PageWizard() {
  const intl = useIntl();
  const theme = useTheme();
  const location = useLocation();
  const locState = location.state as LocationStateProduct | null;
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [locale] = useAppLocale();
  const [alpha, setAlpha] = useState(ALL_VARIANTS);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [debouncedSearchCriteria] = useDebounce(searchCriteria, 1000);
  const [step, setStep] = useState(WizardSteps.SelectMedications);
  const [selectedProgram, setSelectedProgram] = useState<Medication['cards']>();
  const [swiperCtrl, setSwiperCtrl] = useState<SwiperClass | null>(null);
  const [page, setPage] = useState(1);

  const [isAcceptTerms, setAcceptTerms] = useState(false);
  const [isAcceptConsent, setAcceptConsent] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [token, setToken] = useState('');

  const province = query.get('province') ?? 'AB'; // FIXME: Show error instead of using magic default value
  const encCardId = query.get('program');
  const assistanceLevel = query.get('assistanceLevel');
  const generationPoint = query.get('generationPoint');
  const slug = query.get('slug');
  const certPosition = query.get('certPosition');
  const pdfOptions = query.get('pdfOptions');
  const hideHeader = query.get('hideHeader') === 'true';
  const hideNavigation = query.get('hideNavigation') === 'true';
  const hideFooter = query.get('hideFooter') === 'true';
  const customLogoUrl = query.get('customLogoUrl');
  const referralLogoUrl = query.get('referralLogoUrl');
  const referralPartnerBackGroundUrl = query.get(
    'referralPartnerBackGroundUrl',
  );
  const isReferral = query.get('referral') === 'true';
  const queryParams: SearchMedicationsParams = {
    page,
    pageSize,
    alpha: alpha === 'ALL' ? undefined : alpha, // FIXME: We should not know about magic constant 'ALL'
    language: locale,
    province,
    searchCriteria: debouncedSearchCriteria,
    encCardId: encCardId ?? undefined,
    orderBy: locale === Language.English ? 'product:asc' : 'productFr:asc',
  };

  const { data, isLoading: isLoadingMedications } = useQuery(
    [QUERY.DRUGS, queryParams],
    () => searchMedications(queryParams),
  );

  const [basket, setBasket] = useState<Medication[]>(
    locState?.product ? [locState.product] : [],
  );

  const addToBasket = (item: Medication) => {
    setBasket((items) => [...items, item]);
    setAcceptConsent(false);
  };

  useEffect(() => {
    if (pdfOptions === 'None' && step !== WizardSteps.Finish && data?.items) {
      const med: Medication[] = [];
      data.items.forEach((item) => {
        med.push(item);
        addToBasket(item);
      });

      filterUniqueCards(med, encCardId).then((cards) => {
        setSelectedProgram(cards);
        setStep(WizardSteps.Finish);
      });
    }
    // } else if (
    //   data?.items.length === 1 &&
    //   (alpha === null || alpha === 'ALL')
    // ) {
    //   addToBasket(data.items[0]);
    //   setStep(WizardSteps.Consent);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (step === WizardSteps.Consent) {
      filterUniqueCards(basket, encCardId).then((cards) => {
        setSelectedProgram(cards);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const basketIndex = new Set(basket.map((drug) => getDrugId(drug)));
  const { mutate, isLoading } = useMutation<
    unknown,
    unknown,
    LoyaltyObjectRequestData
  >(['create-pass'], (payload) => createPass(payload), {
    onSuccess(e: any) {
      setToken(e);
    },
    onError(e: any) {
      if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error('Cannot send your question. Please try again later.');
      }
    },
  });

  function base64toBlob(base64Data: string) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/vnd.apple.pkpass' });
  }

  const { mutate: mutateApple, isLoading: isLoadingApple } = useMutation<
    unknown,
    unknown,
    LoyaltyObjectRequestData
  >(['create-pass-apple'], (payload) => createPassApple(payload), {
    onSuccess(response: any) {
      const url = window.URL.createObjectURL(
        base64toBlob(response.fileContents),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'rxhelp.pkpass');
      document.body.appendChild(link);
      link.click();
    },
    onError(e: any) {
      if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error('Cannot send your question. Please try again later.');
      }
    },
  });

  const {
    data: cardInfo,
    isLoading: isSubmitting,
    isSuccess: isSuccessCard,
    mutate: createCard,
  } = useMutation(
    ['CreateCard'],
    () =>
      generateCard({
        productIds: basket.map((item) => item.encId),
        assistanceLevel: assistanceLevel
          ? Number.parseInt(assistanceLevel, 10)
          : undefined,
        certificateCardId:
          selectedProgram && selectedProgram?.length > 0
            ? selectedProgram[0].encId
            : undefined,
        province,
        language: locale,
        generationPoint: generationPoint as CertificateGenerationPoint,
        slug,
        notificationEmail,
      }),
    {
      onSuccess() {
        setStep(WizardSteps.Finish);
      },
      onError(err) {
        const apiError = getApiError(err);
        toast.error(String(apiError?.description ?? err));
      },
    },
  );

  useEffect(() => {
    if (
      cardInfo &&
      cardInfo.length > 0 &&
      selectedProgram &&
      selectedProgram.length > 0
    ) {
      mutate({
        issuerId: GOOGLE_API_ISSUER_ID,
        programId: selectedProgram[0].encId,
        barcodeValue: `Cert # ${String(cardInfo[0].certNumber).padStart(
          8,
          '0',
        )}   |   Group # ${cardInfo[0].groupCode}`,
      } as LoyaltyObjectRequestData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInfo]);

  useEffect(() => {
    if (
      step === WizardSteps.Finish &&
      pdfOptions === 'None' &&
      selectedProgram &&
      selectedProgram?.length > 0
    ) {
      setAcceptConsent(true);
      setAcceptTerms(true);
      createCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgram]);

  const removeFromBasket = (item: Medication) => {
    setBasket((items) => items.filter((v) => getDrugId(v) !== getDrugId(item)));
    setAcceptConsent(false);
  };

  const isStepCompleted = (stepItem: WizardSteps, card: Card | null) => {
    const step1completed = true;
    const step2completed =
      basket.length > 0 &&
      STEPS.indexOf(WizardSteps.SelectMedications) < STEPS.indexOf(step);

    const isTermsExist =
      card !== null &&
      convertHTMLToString(
        l10n(
          {
            en: card.termsAndConditions,
            fr: card.frTermsAndConditions,
          },
          locale,
        ),
      );

    const isConsentExists =
      card !== null &&
      convertHTMLToString(
        l10n(
          {
            en: card.legalConsent,
            fr: card.frLegalConsent,
          },
          locale,
        ),
      );

    let accepted = false;
    if (isTermsExist && isConsentExists) {
      accepted = isAcceptTerms && isAcceptConsent;
    } else if (isTermsExist) {
      accepted = isAcceptTerms;
    } else if (isConsentExists) {
      accepted = isAcceptConsent;
    } else {
      accepted = true;
    }

    const step3completed =
      // eslint-disable-next-line no-nested-ternary
      card === null
        ? false
        : isTermsExist ||
          isConsentExists ||
          convertHTMLToString(
            l10n(
              {
                en: card.legalDescription,
                fr: card.frLegalDescription,
              },
              locale,
            ),
          )
        ? accepted
        : true;

    const step4completed =
      step1completed && step2completed && step3completed && isSuccessCard;

    if (stepItem === WizardSteps.Finish && pdfOptions === 'None') {
      return step4completed;
    }
    if (stepItem === WizardSteps.SelectProvince) {
      return step1completed;
    }
    if (stepItem === WizardSteps.SelectMedications) {
      return step2completed;
    }
    if (stepItem === WizardSteps.Consent) {
      return step3completed;
    }
    if (stepItem === WizardSteps.Finish) {
      return step4completed;
    }

    return false;
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageWizard.meta.title',
            defaultMessage: 'Get Card',
          })}
        </title>
      </Helmet>

      <DefaultLayout
        hideHeader={hideHeader}
        hideNavigation={hideNavigation}
        hideFooter={hideFooter}
        customLogoUrl={customLogoUrl ?? undefined}
      >
        <Box sx={{ pt: 2 }} />

        <Container>
          <Typography variant="h1">
            <FormattedMessage
              id={`components.PageWizard.header.${step + 1}`}
              defaultMessage="Medications Selection"
            />
          </Typography>

          <Box sx={{ mb: 2 }} />

          <Stepper nonLinear activeStep={step} alternativeLabel>
            {STEPS.map((label) => (
              <Step
                key={label}
                completed={isStepCompleted(
                  label,
                  selectedProgram && selectedProgram.length > 0
                    ? selectedProgram[0]
                    : null,
                )}
              >
                {label === step ||
                STEPS.indexOf(label) > STEPS.indexOf(step) ? (
                  <StepLabel>
                    {intl.formatMessage(wizardSteps[`wizardSteps.${label}`])}
                  </StepLabel>
                ) : (
                  <StepButton
                    onClick={() => {
                      if (label === WizardSteps.SelectProvince) {
                        if (slug) {
                          if (locale === Language.English) {
                            if (isReferral) {
                              navigate(`/en/r/${slug}`);
                            } else {
                              navigate(`/en/l/${slug}`);
                            }
                          } else if (isReferral) {
                            navigate(`/fr/r/${slug}`);
                          } else {
                            navigate(`/fr/l/${slug}`);
                          }
                        } else if (locale === Language.English) {
                          navigate('/en');
                        } else {
                          navigate('/fr');
                        }
                        return;
                      }
                      setStep(label);
                    }}
                  >
                    {intl.formatMessage(wizardSteps[`wizardSteps.${label}`])}
                  </StepButton>
                )}
              </Step>
            ))}
          </Stepper>
        </Container>

        <Box sx={{ mb: 2 }} />

        {step === WizardSteps.SelectMedications && (
          <>
            <Container>
              {!encCardId && (
                <>
                  <AlphabetSelector
                    availableChars={data?.chars}
                    value={alpha}
                    onChange={(value) => setAlpha(value)}
                  />

                  <Box sx={{ mb: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                      >
                        <FormattedMessage
                          id="components.PageWizard.subtitle.searchMedications"
                          defaultMessage="Search medications"
                        />
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        label={
                          <FormattedMessage
                            id="components.PageWizard.field.medicationTitle"
                            defaultMessage="Enter drug name, generic name or DIN"
                          />
                        }
                        value={searchCriteria}
                        onChange={(event) => {
                          setAlpha(ALL_VARIANTS);
                          setSearchCriteria(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                      >
                        <FormattedMessage
                          id="components.PageWizard.selectAllMedications"
                          defaultMessage="Select all medications that apply and proceed to next step."
                        />
                      </Typography>
                      <Link
                        download
                        href={`/api/products/${province}/pdf?language=${locale}`}
                        target="_blank"
                      >
                        <FormattedMessage
                          id="components.PageWizard.downloadPDFprovinceMedications"
                          defaultMessage="Download PDF of all Medications available for this Province."
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>

            <Box sx={{ mb: 4 }} />

            <Container>
              {/* eslint-disable-next-line no-nested-ternary */}
              {isLoadingMedications ? (
                <Loading />
              ) : (data?.items.length ?? 0) <= 0 ? (
                <Box sx={{ minHeight: '25vh', my: 8 }}>
                  <Alert severity="warning">
                    <FormattedMessage
                      id="components.PageWizard.noMedicationsFound"
                      defaultMessage="No medications found"
                    />
                  </Alert>
                </Box>
              ) : null}
              <Grid container spacing={2}>
                {data?.items.map((item) => (
                  <Grid item key={getDrugId(item)} xs={12} md={6} lg={3}>
                    <ListItem
                      id={item.encId}
                      title={l10n(
                        {
                          en: item.nameHtml,
                          fr: item.frNameHtml,
                        },
                        locale,
                      )}
                      label={l10n(
                        {
                          en: item.genericName,
                          fr: item.frGenericName,
                        },
                        locale,
                      )}
                      promoText={l10n(
                        {
                          en: item.promotionalText,
                          fr: item.frPromotionalText,
                        },
                        locale,
                      )}
                      selected={basketIndex.has(getDrugId(item))}
                      url={l10n(
                        {
                          en: item.logo?.content ?? DEFAULT_LOGO,
                          fr: item.frLogo?.content ?? DEFAULT_LOGO,
                        },
                        locale,
                      )}
                      onClick={() => {
                        if (basketIndex.has(getDrugId(item))) {
                          removeFromBasket(item);
                        } else {
                          addToBasket(item);
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>

            {(data?.totalPages ?? 1) > 1 && (
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  page={page}
                  count={data?.totalPages}
                  onChange={(_, value) => setPage(value)}
                />
              </Box>
            )}

            <Box sx={{ mb: 4 }} />

            <Box
              sx={{
                position: 'sticky',
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: (t) => t.palette.grey[200],
                borderTopStyle: 'solid',
                borderTopColor: (t) => t.palette.divider,
                borderWidth: 2,
              }}
            >
              {basket.length > 0 && (
                <Slide
                  direction="up"
                  in={basket.length > 0}
                  mountOnEnter
                  unmountOnExit
                >
                  <Container sx={{ p: 2 }}>
                    <Typography
                      variant="h4"
                      sx={{ ml: 6, mb: 2, fontWeight: 'bold' }}
                    >
                      <FormattedMessage
                        id="components.PageWizard.basket.title"
                        defaultMessage="My selections:"
                      />
                    </Typography>

                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                    >
                      <IconButton onClick={() => swiperCtrl?.slidePrev()}>
                        <ArrowBackIosIcon />
                      </IconButton>

                      <Swiper
                        modules={[Controller]}
                        onSwiper={setSwiperCtrl}
                        spaceBetween={25}
                        slidesPerView={1}
                        breakpoints={{
                          [theme.breakpoints.values.md]: {
                            slidesPerView: 2,
                          },
                          [theme.breakpoints.values.lg]: {
                            slidesPerView: 4,
                          },
                        }}
                        style={{ width: '100%' }}
                      >
                        {basket.map((item) => (
                          <SwiperSlide key={getDrugId(item)}>
                            <BasketItem
                              id={item.encId}
                              url={l10n(
                                {
                                  en: item.logo?.content ?? DEFAULT_LOGO,
                                  fr: item.frLogo?.content ?? DEFAULT_LOGO,
                                },
                                locale,
                              )}
                              onDelete={() => {
                                removeFromBasket(item);
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <IconButton onClick={() => swiperCtrl?.slideNext()}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Stack>
                  </Container>
                </Slide>
              )}

              <Box
                sx={{
                  borderTopWidth: 1,
                  borderTopStyle: 'solid',
                  borderTopColor: (t) => t.palette.divider,
                }}
              >
                <Container>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      py: 2,
                    }}
                  >
                    {basket.length <= 0 && (
                      <Typography color="text.secondary">
                        <FormattedMessage
                          id="components.PageWizard.basketEmptyHelper"
                          defaultMessage="Choose Medications to continue"
                        />
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      sx={{ minWidth: 220, ml: 2, borderRadius: 100 }}
                      onClick={() => {
                        if (
                          step === WizardSteps.SelectMedications &&
                          pdfOptions === 'None' &&
                          data &&
                          data.items.length > 1
                        ) {
                          setStep(WizardSteps.Finish);
                        } else {
                          setStep((v) => v + 1);
                        }
                      }}
                      disabled={basket.length <= 0}
                    >
                      <FormattedMessage
                        id="components.PageWizard.button.nextStep"
                        defaultMessage="Next"
                      />
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Box>
          </>
        )}

        {step === WizardSteps.Consent && selectedProgram && (
          <Container sx={{ py: 2 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                component="img"
                src={l10n(
                  {
                    en:
                      selectedProgram.slice(0, 1)[0].pdfLogo?.content ??
                      selectedProgram.slice(0, 1)[0].logo?.content ??
                      '/2023_RxHelpONElogo1.png',
                    fr:
                      selectedProgram.slice(0, 1)[0].frPdfLogo?.content ??
                      selectedProgram.slice(0, 1)[0].frLogo?.content ??
                      '/2023_RxHelpONElogo1_FR.png',
                  },
                  locale,
                )}
                maxWidth={266}
                maxHeight={112}
              />

              {selectedProgram &&
                selectedProgram.length > 0 &&
                l10n(
                  {
                    en: selectedProgram[0].programDescription,
                    fr: selectedProgram[0].frProgramDescription,
                  },
                  locale,
                ) &&
                convertHTMLToString(
                  l10n(
                    {
                      en: selectedProgram[0].programDescription,
                      fr: selectedProgram[0].frProgramDescription,
                    },
                    locale,
                  ),
                ) && (
                  <Typography variant="body1" key={selectedProgram[0].encId}>
                    {
                      <MarkerDiv
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: l10n(
                            {
                              en: selectedProgram[0].programDescription,
                              fr: selectedProgram[0].frProgramDescription,
                            },
                            locale,
                          ),
                        }}
                      />
                    }
                  </Typography>
                )}
            </Stack>

            <Box sx={{ mb: 2 }} />

            {(convertHTMLToString(
              l10n(
                {
                  en: selectedProgram[0].termsAndConditions,
                  fr: selectedProgram[0].frTermsAndConditions,
                },
                locale,
              ),
            ) ||
              convertHTMLToString(
                l10n(
                  {
                    en: selectedProgram[0].legalConsent,
                    fr: selectedProgram[0].frLegalConsent,
                  },
                  locale,
                ),
              ) ||
              convertHTMLToString(
                l10n(
                  {
                    en: selectedProgram[0].legalDescription,
                    fr: selectedProgram[0].frLegalDescription,
                  },
                  locale,
                ),
              )) && (
              <>
                <Typography variant="h3">
                  <FormattedMessage
                    id="component.PageWizard.consent.legalConsent.headerGroup"
                    defaultMessage="Legal Consent"
                  />
                </Typography>

                <Box sx={{ mb: 2 }} />

                {selectedProgram &&
                  convertHTMLToString(
                    l10n(
                      {
                        en: selectedProgram[0].termsAndConditions,
                        fr: selectedProgram[0].frTermsAndConditions,
                      },
                      locale,
                    ),
                  ) && (
                    <Box key={`${selectedProgram[0].encId} 1`} sx={{ my: 0 }}>
                      <FormControlLabel
                        sx={{
                          overflowWrap: 'anywhere',
                          alignItems: 'flex-start',
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                        label={
                          <MarkerDiv
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: l10n(
                                {
                                  en: selectedProgram[0].termsAndConditions,
                                  fr: selectedProgram[0].frTermsAndConditions,
                                },
                                locale,
                              ).replace('<a', '<a target="_blank"'),
                            }}
                          />
                        }
                        control={
                          <Checkbox
                            sx={{
                              marginTop: -1,
                            }}
                            checked={isAcceptTerms}
                            onChange={(_, value) => setAcceptTerms(value)}
                          />
                        }
                      />
                    </Box>
                  )}

                {selectedProgram &&
                  convertHTMLToString(
                    l10n(
                      {
                        en: selectedProgram[0].legalConsent,
                        fr: selectedProgram[0].frLegalConsent,
                      },
                      locale,
                    ),
                  ) && (
                    <Box key={`${selectedProgram[0].encId} 2`} sx={{ my: 0 }}>
                      <FormControlLabel
                        sx={{
                          overflowWrap: 'anywhere',
                          alignItems: 'flex-start',
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                        label={
                          <MarkerDiv
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: l10n(
                                {
                                  en: selectedProgram[0].legalConsent,
                                  fr: selectedProgram[0].frLegalConsent,
                                },
                                locale,
                              ).replace('<a', '<a target="_blank"'),
                            }}
                          />
                        }
                        control={
                          <Checkbox
                            sx={{
                              marginTop: -1,
                            }}
                            checked={isAcceptConsent}
                            onChange={(_, value) => setAcceptConsent(value)}
                          />
                        }
                      />
                    </Box>
                  )}

                <Box sx={{ mb: 2 }} />

                {selectedProgram &&
                  convertHTMLToString(
                    l10n(
                      {
                        en: selectedProgram[0].legalDescription,
                        fr: selectedProgram[0].frLegalDescription,
                      },
                      locale,
                    ),
                  ) && (
                    <Typography
                      variant="body1"
                      key={`${selectedProgram[0].encId} 3`}
                    >
                      <FormattedMessage
                        id="0"
                        defaultMessage={convertHTMLToString(
                          l10n(
                            {
                              en: selectedProgram[0].legalDescription,
                              fr: selectedProgram[0].frLegalDescription,
                            },
                            locale,
                          ),
                        )}
                      />
                    </Typography>
                  )}

                <Box sx={{ mb: 2 }} />
              </>
            )}

            <Typography variant="h3">
              <FormattedMessage
                id="component.PageWizard.consent.contactOptions.headerGroup"
                defaultMessage="Contact Options"
              />
            </Typography>

            <Box sx={{ mb: 2 }} />

            <FormControlLabel
              sx={{
                overflowWrap: 'anywhere',
                alignItems: 'flex-start',
                paddingTop: 1,
                paddingBottom: 1,
              }}
              label={
                <FormattedMessage
                  id="components.PageWizard.field.emailNotification"
                  defaultMessage="I would like to be emailed when new products are added or there are any changes to coverage of products on RxHelp.ca. I may withdraw my consent at any time."
                />
              }
              control={
                <Checkbox
                  sx={{
                    marginTop: -1,
                  }}
                />
              }
            />
            <TextField
              value={notificationEmail}
              onChange={(e) => {
                setNotificationEmail(e.target.value);
              }}
              label={
                <FormattedMessage
                  id="components.PageWizard.field.enterEmailAddress"
                  defaultMessage="Email address"
                />
              }
              sx={{ ml: 3, mt: 1 }}
            />

            <Box sx={{ mb: 2 }} />

            <FormControlLabel
              sx={{
                overflowWrap: 'anywhere',
                alignItems: 'flex-start',
                paddingTop: 1,
                paddingBottom: 1,
              }}
              label={
                <FormattedMessage
                  id="components.PageWizard.field.futureOpportunity"
                  defaultMessage="I am willing to be contacted in the future regarding the opportunity to participate in compensated and non-compensated market research studies. I may withdraw my consent at any time."
                />
              }
              control={
                <Checkbox
                  sx={{
                    marginTop: -1,
                  }}
                />
              }
            />

            <Box sx={{ mb: 2 }} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: 'text.secondary', mr: 2 }}>
                <FormattedMessage
                  id="components.PageWizard.consentStep.helper"
                  defaultMessage="Apply terms to continue"
                />
              </Typography>
              <LoadingButton
                variant="contained"
                sx={{ minWidth: 200, borderRadius: 100 }}
                disabled={
                  !isStepCompleted(WizardSteps.Consent, selectedProgram[0])
                }
                loading={isSubmitting}
                onClick={() => {
                  createCard();
                }}
              >
                <FormattedMessage
                  id="components.PageWizard.button.consent.nextStep"
                  defaultMessage="Next"
                />
              </LoadingButton>
            </Box>
          </Container>
        )}

        {step === WizardSteps.Finish && cardInfo && selectedProgram && (
          <>
            <Container sx={{ py: 2 }}>
              {cardInfo.map((card) => (
                <CardInfo
                  key={card.encId}
                  card={card}
                  certPosition={certPosition as CardLayout}
                  customBackgroundUrl={referralPartnerBackGroundUrl}
                  topLogoUrl={
                    referralLogoUrl === null || !referralLogoUrl
                      ? undefined
                      : referralLogoUrl
                  }
                />
              ))}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonBase href={`https://pay.google.com/gp/v/save/${token}`}>
                  <img
                    alt="RxHelp"
                    src={l10n(
                      {
                        en: '/add-to-google-wallet.png',
                        fr: '/add-to-google-wallet-fr.png',
                      },
                      locale,
                    )}
                    style={{ maxWidth: 199, maxHeight: 70, marginRight: 20 }}
                  />
                </ButtonBase>
                <ButtonBase
                  onClick={() =>
                    mutateApple({
                      issuerId: GOOGLE_API_ISSUER_ID,
                      programId: selectedProgram[0].encId,
                      barcodeValue: `Cert # ${String(
                        cardInfo[0].certNumber,
                      ).padStart(8, '0')}   |   Group # ${
                        cardInfo[0].groupCode
                      }`,
                    } as LoyaltyObjectRequestData)
                  }
                >
                  <img
                    alt="RxHelp"
                    src={l10n(
                      {
                        en: '/add-to-apple-wallet.svg',
                        fr: '/add-to-apple-wallet-fr.svg',
                      },
                      locale,
                    )}
                    style={{
                      maxWidth: 199,
                      maxHeight: 70,
                      width: 199,
                      marginLeft: 20,
                    }}
                  />
                </ButtonBase>
              </div>
            </Container>

            {selectedProgram &&
              selectedProgram.length > 0 &&
              (selectedProgram[0].url || selectedProgram[0].frUrl) &&
              selectedProgram[0].urlRequired && (
                <>
                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      style={{ textAlign: 'center' }}
                      href={`${l10n(
                        {
                          en: selectedProgram[0].url,
                          fr: selectedProgram[0].frUrl,
                        },
                        locale,
                      )}`}
                    >
                      {l10n(
                        {
                          en: selectedProgram[0].urlText,
                          fr: selectedProgram[0].frUrlText,
                        },
                        locale,
                      )}
                    </Link>
                  </Box>
                </>
              )}

            <Box sx={{ mb: 2 }} />

            <PharmacyLocator />
          </>
        )}
      </DefaultLayout>
    </>
  );
}
