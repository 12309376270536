import { defineMessages } from 'react-intl';

export const wizardSteps = defineMessages({
  'wizardSteps.0': {
    id: 'wizardSteps.0',
    defaultMessage: 'Select Province',
  },
  'wizardSteps.1': {
    id: 'wizardSteps.1',
    defaultMessage: 'Select Medications',
  },
  'wizardSteps.2': {
    id: 'wizardSteps.2',
    defaultMessage: 'Consent',
  },
  'wizardSteps.3': {
    id: 'wizardSteps.3',
    defaultMessage: 'Complete',
  },
});
