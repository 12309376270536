import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Box, Container, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { generatePath, useParams } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { Loading } from '../../components/Loading';
import { PharmacyLocator } from '../../components/PharmacyLocator';
import { getNewsList } from '../../lib/api/getNewsList';
import { ROUTES } from '../../router';
import { l10n } from '../../Language';

const queryParams = {
  pageSize: 50,
  page: 1,
  orderBy: 'createDate:desc',
} as const;

export function PageSitemap() {
  const intl = useIntl();
  const { lang } = useParams();
  const [locale] = useAppLocale();
  const { isLoading, data, error } = useQuery([QUERY.NEWS, queryParams], () =>
    getNewsList(queryParams),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageSitemap.meta.title',
            defaultMessage: 'Sitemap',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <>
              <Typography variant="h1">
                <FormattedMessage
                  id="components.PageSitemap.title"
                  defaultMessage="Sitemap"
                />
              </Typography>

              <Box component="ul">
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.HOME, { lang: lang ?? 'en' })}
                  >
                    <FormattedMessage
                      id="components.PageHome.meta.title"
                      defaultMessage="Home"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.ABOUT, { lang: lang ?? 'en' })}
                  >
                    <FormattedMessage
                      id="components.PageAbout.meta.title"
                      defaultMessage="About Us"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.NEWS_LIST, {
                      lang: lang ?? 'en',
                    })}
                  >
                    <FormattedMessage
                      id="components.PageNews.meta.title"
                      defaultMessage="What's New"
                    />
                  </Link>
                  <Box component="ul">
                    {data?.items.map((item) => (
                      <Box key={item.encId} component="li">
                        <Link
                          href={generatePath(ROUTES.NEWS_DETAILS, {
                            id: item.encId,
                            lang: lang ?? 'en',
                          })}
                        >
                          {l10n({ en: item.title, fr: item.frTitle }, locale)}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.PLAN, { lang: lang ?? 'en' })}
                  >
                    <FormattedMessage
                      id="components.PageEmployers.meta.title"
                      defaultMessage="Employers & Benefit Plans"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link href={generatePath(ROUTES.FAQ, { lang: lang ?? 'en' })}>
                    <FormattedMessage
                      id="components.PageFAQ.meta.title"
                      defaultMessage="FAQ"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.CONTACT, { lang: lang ?? 'en' })}
                  >
                    <FormattedMessage
                      id="components.PageContactUs.meta.title"
                      defaultMessage="Contact Us"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.COMPANY_REGISTRATION, {
                      lang: lang ?? 'en',
                    })}
                  >
                    <FormattedMessage
                      id="components.PagePharmacyLocatorRegistration.meta.title"
                      defaultMessage="Pharmacy Locator — Company Registration"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.PRIVACY_POLICY, {
                      lang: lang ?? 'en',
                    })}
                  >
                    <FormattedMessage
                      id="components.PagePrivacyPolicy.meta.title"
                      defaultMessage="Privacy Policy"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.TERMS_OF_USE, {
                      lang: lang ?? 'en',
                    })}
                  >
                    <FormattedMessage
                      id="components.PageTermsOfUse.meta.title"
                      defaultMessage="Terms of Use"
                    />
                  </Link>
                </Box>
                <Box component="li">
                  <Link
                    href={generatePath(ROUTES.PHARMACY_LOCATOR, {
                      lang: lang ?? 'en',
                    })}
                  >
                    <FormattedMessage
                      id="components.PagePharmacyLocator.meta.title"
                      defaultMessage="Pharmacy Locator"
                    />
                  </Link>
                </Box>
              </Box>
            </>
          )}
        </Container>

        <PharmacyLocator />
      </DefaultLayout>
    </>
  );
}
