import React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { Alert, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { l10n } from '../../Language';
import { getTermsOfUse } from '../../lib/api/getTermsOfUse';
import { Loading } from '../../components/Loading';

const MarkerDiv = styled.div`
  ol {
    list-style-position: inside;
    margin-top: 2px;
  }
  ol li:not(.ql-indent-1) {
    counter-increment: list-0;
    counter-reset: alpha;
  }
  ol li:not(.ql-indent-1)::marker {
    content: counter(list-0, decimal) '. ';
  }
  ol li.ql-indent-1 {
    counter-increment: alpha;
    padding-left: 4.5em;
  }
  ol li.ql-indent-1::marker {
    content: counter(alpha, lower-alpha) '. ';
  }
  p {
    margin: 1px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`;

export function PageTermsOfUse() {
  const intl = useIntl();
  const [locale] = useAppLocale();

  const { isLoading, data, error } = useQuery([QUERY.TERMS_OF_USE], () =>
    getTermsOfUse(),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageTermsOfUse.meta.title',
            defaultMessage: 'Terms of Use',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <MarkerDiv
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: l10n(
                  { en: data?.details ?? '', fr: data?.frDetails ?? '' },
                  locale,
                ),
              }}
            />
          )}
        </Container>
      </DefaultLayout>
    </>
  );
}
