import { defineMessages } from 'react-intl';

export const companyType = defineMessages({
  'companyType.pharmacist': {
    id: 'companyType.pharmacist',
    defaultMessage: 'Pharmacist',
  },
  'companyType.physician': {
    id: 'companyType.physician',
    defaultMessage: 'Physician',
  },
});
