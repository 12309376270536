import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { GetNewsListParams, getNewsList } from '../../lib/api/getNewsList';
import { l10n } from '../../Language';
import { useAppLocale } from '../AppLocaleProvider';
import { ROUTES } from '../../router';
import { QUERY } from '../../queryClient';

const queryParams: GetNewsListParams = {
  page: 1,
  pageSize: 3,
  orderBy: 'createDate:desc',
} as const;

export function HomeNewsBlock() {
  const [locale] = useAppLocale();
  const { lang } = useParams();
  const { data } = useQuery([QUERY.NEWS, queryParams], () =>
    getNewsList(queryParams),
  );

  if (!data?.items.length) {
    return null;
  }

  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: (t) => t.palette.primary.dark,
      }}
      paddingTop={{ xs: '57px', sm: '80px' }}
    >
      <Container>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0, sm: 1 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            paddingRight={{ sm: '50px' }}
            paddingBottom={{ xs: '40px' }}
            style={{
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            <FormattedMessage
              id="components.HomeNewsBlock.title"
              defaultMessage="What's New"
            />
          </Typography>
          <Grid container spacing={2} width={{ sm: '80%' }}>
            {data?.items.map((item) => (
              <>
                <Grid
                  key={item.encId}
                  item
                  xs={12}
                  sm={4}
                  maxWidth={{ sm: '32%' }}
                  style={{
                    paddingTop: '3px',
                    paddingLeft: '0px',
                  }}
                >
                  <Link
                    href={generatePath(ROUTES.NEWS_DETAILS, {
                      id: item.encId,
                      lang: lang ?? 'en',
                    })}
                  >
                    <Typography
                      variant="h3"
                      style={{
                        color: 'white',
                        paddingLeft: 40,
                        paddingRight: 40,
                        wordWrap: 'break-word',
                      }}
                    >
                      {l10n({ en: item.title, fr: item.frTitle }, locale)}
                    </Typography>
                  </Link>
                  <Stack
                    direction="row"
                    spacing={1 / 2}
                    sx={{ my: 1, alignItems: 'center' }}
                  >
                    <CalendarMonthIcon
                      style={{
                        color: 'white',
                        paddingLeft: 40,
                        width: 'unset',
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{ color: 'white', marginTop: 4 }}
                    >
                      <FormattedDate
                        month="long"
                        year="numeric"
                        day="numeric"
                        value={item.startDate}
                      />
                    </Typography>
                  </Stack>
                </Grid>
                {data?.items.indexOf(item) < (data?.items.length ?? 1) - 1 && (
                  <Box
                    sx={{
                      backgroundColor: '#9cb9c5',
                      width: '1px',
                      display: 'flex',
                    }}
                  />
                )}
              </>
            ))}
          </Grid>
        </Stack>

        <Box sx={{ mb: 4 }} />
      </Container>
    </Box>
  );
}
