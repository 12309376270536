import { httpClient } from '../httpClient';
import { Language } from '../../Language';

export interface Province {
  key: string;
  province: string;
  frProvince: string;
}

export interface FetchProvincesParams {
  isActive: boolean;
  language: Language;
}

export function fetchProvinces(params?: FetchProvincesParams) {
  if (params?.isActive) {
    return httpClient.get<FetchProvincesParams, Province[]>(
      '/cards/active/provinces',
      params,
    );
  }

  // TODO: Replace with API call (`/provinces` returns 404 now)
  return Promise.resolve([
    {
      key: 'AB',
      province: 'Alberta',
      frProvince: 'Alberta',
    },
    {
      key: 'BC',
      province: 'British Columbia',
      frProvince: 'Colombie-Britannique',
    },
    {
      key: 'MB',
      province: 'Manitoba',
      frProvince: 'Manitoba',
    },
    {
      key: 'NB',
      province: 'New Brunswick',
      frProvince: 'Nouveau-Brunswick',
    },
    {
      key: 'NL',
      province: 'Newfoundland and Labrador',
      frProvince: 'Terre-Neuve-et-Labrador',
    },
    {
      key: 'NS',
      province: 'Nova Scotia',
      frProvince: 'Nouvelle-Écosse',
    },
    {
      key: 'NT',
      province: 'Northwest Territories',
      frProvince: 'Territoires du Nord-Ouest',
    },
    {
      key: 'NU',
      province: 'Nunavut',
      frProvince: 'Nunavut',
    },
    {
      key: 'ON',
      province: 'Ontario',
      frProvince: 'Ontario',
    },
    {
      key: 'PE',
      province: 'Prince Edward Island',
      frProvince: 'Île-du-Prince-Édouard',
    },
    {
      key: 'QC',
      province: 'Quebec',
      frProvince: 'Québec',
    },
    {
      key: 'SK',
      province: 'Saskatchewan',
      frProvince: 'Saskatchewan',
    },
    {
      key: 'YT',
      province: 'Yukon',
      frProvince: 'Yukon',
    },
  ]);
}
