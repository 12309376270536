import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { AppThemeProvider } from './components/AppThemeProvider';
import { AppLocaleProvider } from './components/AppLocaleProvider';
import { queryClient } from './queryClient';

import 'react-toastify/dist/ReactToastify.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppLocaleProvider>
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </AppThemeProvider>
      </QueryClientProvider>
    </AppLocaleProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
