import React from 'react';
import { useQuery } from 'react-query';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useAppLocale } from '../AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { fetchProvinces } from '../../lib/api/fetchProvinces';
import { Language, l10n } from '../../Language';

interface ProvinceSelectorProps {
  isOnlyActive?: boolean;
  language: Language;
}

export function ProvinceSelector({
  isOnlyActive = false,
  language = Language.English,
  ...props
}: TextFieldProps & ProvinceSelectorProps) {
  const [locale] = useAppLocale();
  const queryParams = { isActive: isOnlyActive, language } as const;
  const { data, isLoading, error } = useQuery(
    [QUERY.PROVINCES, queryParams],
    () => fetchProvinces(queryParams),
  );

  return (
    <TextField
      select
      sx={{ minWidth: 220 }}
      disabled={isLoading || !!error}
      error={!!error}
      helperText={error ? String(error) : undefined}
      {...props}
    >
      <MenuItem disabled defaultChecked value="" sx={{ display: 'none' }} />
      {data?.map((province) => (
        <MenuItem key={province.key} value={province.key}>
          {l10n({ en: province.province, fr: province.frProvince }, locale)}
        </MenuItem>
      ))}
    </TextField>
  );
}
