import React, { useState } from 'react';
import printJs from 'print-js';
import { Box, Button, Stack, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { GenerateCardResponse } from '../../lib/api/generateCard';
import { CardLayout, RxHelpCard } from '../../components/RxHelpCard';
import { sendCardToEmail } from '../../lib/api/sendCardToEmail';
import { l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';

interface CardInfoProps {
  card: GenerateCardResponse;
  certPosition: CardLayout | null;
  topLogoUrl?: string;
  customBackgroundUrl?: string | null;
}

export function CardInfo({
  card,
  certPosition,
  topLogoUrl,
  customBackgroundUrl,
}: CardInfoProps) {
  const [locale] = useAppLocale();
  const [email, setEmail] = useState('');

  const { mutate } = useMutation(
    ['sendCardToEmail'],
    () => sendCardToEmail(card.encId, email),
    {
      onSuccess() {
        toast.success('Card information sent to email.');
        setEmail('');
      },
      onError() {
        toast.error('Cannot send card information to email');
      },
    },
  );

  return (
    <Box sx={{ mb: 6 }}>
      {card.pdfOptions === 0 && (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <h1>
            <FormattedMessage
              id="0"
              defaultMessage={`Cert # ${String(card.certNumber).padStart(
                8,
                '0',
              )}   |   Group # ${card.groupCode}`}
            />
          </h1>
        </div>
      )}
      {card.pdfOptions !== 0 && (
        <>
          <Box
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              mutate();
            }}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Button
                variant="contained"
                type="button"
                disabled={card.pdfSettings === 2}
                onClick={() => {
                  printJs(
                    `/api/artifacts/certificates/${card.encId}/pdf`,
                    'pdf',
                  );
                }}
              >
                <FormattedMessage
                  id="components.PageWizard.button.printCard"
                  defaultMessage="Print Card"
                />
              </Button>
              <Button
                download
                disabled={card.pdfSettings === 2}
                type="button"
                target="_blank"
                href={`/api/artifacts/certificates/${card.encId}/pdf`}
                variant="contained"
              >
                <FormattedMessage
                  id="components.PageWizard.button.downloadCardPdf"
                  defaultMessage="Download PDF Card"
                />
              </Button>
              <TextField
                required
                disabled={card.pdfSettings === 1}
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label={
                  <FormattedMessage
                    id="components.PageWizard.field.enterEmailAddress"
                    defaultMessage="Email address"
                  />
                }
              />
              <Button
                disabled={card.pdfSettings === 1}
                type="submit"
                variant="contained"
              >
                <FormattedMessage
                  id="components.PageWizard.button.sendCardToEmail"
                  defaultMessage="Send to email"
                />
              </Button>
            </Stack>
          </Box>

          <Box sx={{ mb: 6 }} />

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {(!card?.cardImage?.content || !card?.frCardImage?.content) &&
              card.pdfOptions !== 2 && (
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  <h1>
                    <FormattedMessage
                      id="0"
                      defaultMessage={`Cert # ${String(
                        card.certNumber,
                      ).padStart(8, '0')}   |   Group # ${card.groupCode}`}
                    />
                  </h1>
                </div>
              )}
            {card?.cardImage?.content && card?.frCardImage?.content && (
              <RxHelpCard
                imgUrl={
                  customBackgroundUrl ??
                  l10n(
                    {
                      en:
                        card.cardImage.content ?? '/program-card-rxhelpone.png',
                      fr:
                        card.frCardImage.content ??
                        '/program-card-rxhelpone-fr.png',
                    },
                    locale,
                  )
                }
                topImgUrl={topLogoUrl ?? undefined}
                noLabels={card.pdfOptions === 2 ? true : undefined}
                layout={certPosition ?? card.certificatePosition}
                cert={card.certNumber}
                group={card.groupCode}
                certificateTextColor={card.certificateTextColor}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
