import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export function SizeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 384 512">
      <path d="M381.5 435.7l-160-384C216.6 39.78 204.9 32.01 192 32.01S167.4 39.78 162.5 51.7l-160 384c-6.797 16.31 .9062 35.05 17.22 41.84c16.38 6.828 35.08-.9219 41.84-17.22l31.8-76.31h197.3l31.8 76.31c5.109 12.28 17.02 19.7 29.55 19.7c4.094 0 8.266-.7969 12.3-2.484C380.6 470.7 388.3 452 381.5 435.7zM119.1 320L192 147.2l72 172.8H119.1z" />
    </SvgIcon>
  );
}
