import React, { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { SearchBy } from '../../lib/api/searchPharmacy';
import { ROUTES } from '../../router';
import { ProvinceSelector } from '../../components/ProvinceSelector';
import { useAppLocale } from '../../components/AppLocaleProvider';

export function PagePharmacyLocator() {
  const intl = useIntl();
  const { lang } = useParams();
  const [searchBy, setSearchBy] = useState(SearchBy.PostalCode);
  const [locale] = useAppLocale();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PagePharmacyLocator.meta.title',
            defaultMessage: 'Pharmacy Locator',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ my: 2 }}>
          <Typography variant="h1">
            <FormattedMessage
              id="components.PagePharmacyLocator.header"
              defaultMessage="Pharmacy Locator"
            />
          </Typography>

          <Typography sx={{ fontWeight: 'bold', my: 1 }}>
            <FormattedMessage
              id="components.PagePharmacyLocator.subtitle"
              defaultMessage="Type in any of the following information to find pharmacies in your area that have indicated that they will accept RxHelp Program Cards."
            />
          </Typography>

          <Typography>
            <FormattedMessage
              id="components.PagePharmacyLocator.subtitle2"
              defaultMessage="Pharmacists <link>click here</link> if you would like your pharmacy added to the Pharmacy Locator."
              values={{
                link: (msg) => (
                  <Link
                    href={generatePath(ROUTES.COMPANY_REGISTRATION, {
                      lang: lang ?? 'en',
                    })}
                  >
                    {msg}
                  </Link>
                ),
              }}
            />
          </Typography>

          <Paper sx={{ mt: 2, p: 2 }}>
            <Typography variant="body1" sx={{ fontSize: '1.25em' }}>
              <FormattedMessage
                id="components.PagePharmacyLocator.searchForm.title"
                defaultMessage="Search By Postal, City or Pharmacy Name"
              />
            </Typography>

            <Box sx={{ mb: 2 }} />

            <Box
              component="form"
              method="GET"
              action={generatePath(ROUTES.PHARMACY_LOCATOR_SERP, {
                lang: lang ?? 'en',
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    name="searchBy"
                    size="small"
                    fullWidth
                    label={
                      <FormattedMessage
                        id="components.PagePharmacyLocator.field.searchBy"
                        defaultMessage="Search by"
                      />
                    }
                    value={searchBy}
                    onChange={(event) => {
                      setSearchBy(event.target.value as typeof searchBy);
                    }}
                  >
                    <MenuItem value={SearchBy.PostalCode}>
                      <FormattedMessage
                        id="components.PagePharmacyLocator.field.searchBy.option.postalCode"
                        defaultMessage="Postal Code"
                      />
                    </MenuItem>
                    <MenuItem value={SearchBy.City}>
                      <FormattedMessage
                        id="components.PagePharmacyLocator.field.searchBy.option.city"
                        defaultMessage="City"
                      />
                    </MenuItem>
                    <MenuItem value={SearchBy.PharmacyName}>
                      <FormattedMessage
                        id="components.PagePharmacyLocator.field.searchBy.option.pharmacyName"
                        defaultMessage="Pharmacy Name"
                      />
                    </MenuItem>
                  </TextField>
                </Grid>

                {searchBy === SearchBy.PostalCode && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="postalCode"
                        size="small"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="components.PagePharmacyLocator.field.postalCode"
                            defaultMessage="Postal Code"
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        name="within"
                        size="small"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="components.PagePharmacyLocator.field.within"
                            defaultMessage="Within"
                          />
                        }
                      >
                        <MenuItem value="5">5 Km</MenuItem>
                        <MenuItem value="10">10 Km</MenuItem>
                        <MenuItem value="15">15 Km</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}

                {searchBy === SearchBy.City && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name="city"
                        size="small"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="components.PagePharmacyLocator.field.city"
                            defaultMessage="City"
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <ProvinceSelector
                        language={locale}
                        name="province"
                        size="small"
                        fullWidth
                        label={
                          <FormattedMessage
                            id="components.PagePharmacyLocator.field.province"
                            defaultMessage="Province"
                          />
                        }
                      />
                    </Grid>
                  </>
                )}

                {searchBy === SearchBy.PharmacyName && (
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="pharmacyName"
                      size="small"
                      fullWidth
                      label={
                        <FormattedMessage
                          id="components.PagePharmacyLocator.field.pharmacyName"
                          defaultMessage="Pharmacy Name"
                        />
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{ borderRadius: 100 }}
                  >
                    <FormattedMessage
                      id="components.PagePharmacyLocator.button.search"
                      defaultMessage="Search"
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </DefaultLayout>
    </>
  );
}
