import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { SupporterLogo } from '../../components/SupporterLogo';

interface BasketItemProps {
  id: string;
  url: string;
  onDelete: (id: string) => void;
}

export function BasketItem({ id, url, onDelete }: BasketItemProps) {
  return (
    <Box sx={{ position: 'relative', mt: 2.5, mr: 2.5 }}>
      <SupporterLogo url={url} />
      <IconButton
        onClick={() => onDelete(id)}
        sx={{ position: 'absolute', top: -20, right: -20 }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}
