import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import Reaptcha from 'reaptcha';
import { toast } from 'react-toastify';
import {
  ContactUsReason,
  SendContactUsData,
  sendContactUs,
} from '../../lib/api/sendContactUs';
import { contactUsReason } from './ContactUsForm.translations';
import { env } from '../../app/.shared/settings/env';

export function ContactUsForm() {
  const intl = useIntl();
  let captchaRef: Reaptcha | null = null;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      reason: ContactUsReason.Other,
      message: '',
      acceptTerms: false,
      isVerified: false,
      verifyToken: '',
    },
  });

  const { mutate, isLoading } = useMutation<
    unknown,
    unknown,
    SendContactUsData
  >(['SendContactUs'], (payload) => sendContactUs(payload), {
    onSuccess() {
      toast.success('Your question successfully sent');
      captchaRef?.reset();
      captchaRef = null;
      reset();
    },
    onError(e: any) {
      captchaRef?.reset();
      captchaRef = null;
      if (e.response.data.description) {
        toast.error(e.response.data.description);
      } else {
        toast.error('Cannot send your question. Please try again later.');
      }
    },
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data, e) => {
        if (!data.isVerified) {
          setError('isVerified', {
            type: 'custom',
            message: 'Recaptcha is not valid',
          });
        } else if (!data.verifyToken || data.verifyToken === '') {
          setError('isVerified', {
            type: 'custom',
            message: 'Recaptcha is not valid',
          });
        } else {
          clearErrors('isVerified');
          e?.preventDefault();
          mutate(data);
        }
      })}
    >
      <Typography variant="h3">
        <FormattedMessage
          id="components.PageContactUs.form.header"
          defaultMessage="Contact Us"
        />
      </Typography>

      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <TextField
            fullWidth
            required
            margin="normal"
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.name"
                defaultMessage="Name"
              />
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextField
            fullWidth
            required
            margin="normal"
            type="email"
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.email"
                defaultMessage="Email Address"
              />
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <TextField
            fullWidth
            required
            margin="normal"
            type="tel"
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.phone"
                defaultMessage="Phone Number"
              />
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="reason"
        render={({ field }) => (
          <TextField
            select
            fullWidth
            required
            margin="normal"
            type="tel"
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.reason"
                defaultMessage="Reason"
              />
            }
            {...field}
          >
            {Object.values(ContactUsReason).map((item) => (
              <MenuItem key={item} value={item}>
                {intl.formatMessage(
                  contactUsReason[
                    `components.PageContactUs.form.field.reason.${item}`
                  ],
                )}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        control={control}
        name="message"
        render={({ field }) => (
          <TextField
            fullWidth
            required
            margin="normal"
            multiline
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.message"
                defaultMessage="Message"
              />
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="acceptTerms"
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox required />}
            label={
              <FormattedMessage
                id="components.PageContactUs.form.field.acceptTerms"
                defaultMessage="By checking this box I confirm that I have read, understood and consented to the RxHelp.ca Privacy Policy and Terms and Conditions."
              />
            }
            {...field}
          />
        )}
      />

      <Box sx={{ mb: 2 }} />

      <Reaptcha
        sitekey={env.GOOGLE_RECAPTCHA_PUBLIC_KEY}
        ref={(e) => {
          if (e !== null) {
            captchaRef = e;
          }
        }}
        onVerify={(recaptchaResponse) => {
          setValue('isVerified', true, { shouldValidate: true });
          clearErrors('isVerified');
          setValue('verifyToken', recaptchaResponse, { shouldValidate: true });
        }}
      />
      {errors.isVerified && (
        <p style={{ color: 'red' }}>{errors.isVerified.message}</p>
      )}

      <Box sx={{ mb: 2 }} />

      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        loading={isLoading}
        sx={{ borderRadius: 100 }}
      >
        <FormattedMessage
          id="components.PageCOntactUs.form.button.submit"
          defaultMessage="Submit"
        />
      </LoadingButton>
    </Box>
  );
}
