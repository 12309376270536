import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Language } from '../../Language';

import messages from '../../locale/dist/en.json';
import frMessages from '../../locale/dist/fr.json';

function getMessagesForLocale(lang: Language) {
  if (lang === Language.French) {
    return frMessages;
  }
  return messages;
}

function validateLocales(locale: unknown): locale is Language {
  return Object.values(Language).includes(locale as Language);
}

const localeFromStorage = localStorage.getItem('locale');

function currentLocale(lang: string) {
  // eslint-disable-next-line no-nested-ternary
  return validateLocales(localeFromStorage)
    ? lang === 'fr'
      ? Language.French
      : Language.English
    : Language.English;
}

const AppLocaleContext = createContext<
  [Language, React.Dispatch<React.SetStateAction<Language>>]
>([
  currentLocale('en'),
  () => {
    // noop
  },
]);

interface AppLocaleProviderProps {
  children: React.ReactNode;
}

export function AppLocaleProvider({ children }: AppLocaleProviderProps) {
  const { lang } = useParams();
  const state = useState(currentLocale(lang ?? 'en'));
  const [locale] = state;

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <AppLocaleContext.Provider value={state}>
      <IntlProvider
        messages={getMessagesForLocale(locale)}
        locale={locale}
        defaultLocale={Language.English}
      >
        {children}
      </IntlProvider>
    </AppLocaleContext.Provider>
  );
}

export function useAppLocale() {
  return useContext(AppLocaleContext);
}
