import { httpClient } from '../httpClient';

export interface LoyaltyObjectRequestData {
  programId: string;
  issuerId: string;
  loyaltyObjectId: string;
  loyaltyClassId: string;
  barcodeValue: string;
}

export function createPass(payload: LoyaltyObjectRequestData) {
  return httpClient.post<LoyaltyObjectRequestData, void>(
    '/wallet/create-pass',
    {
      ...payload,
    },
  );
}

export function createPassApple(payload: LoyaltyObjectRequestData) {
  return httpClient.post<LoyaltyObjectRequestData, void>(
    '/wallet/create-pass-apple',
    {
      ...payload,
    },
  );
}
