import { QueryClient } from 'react-query';

export const queryClient = new QueryClient();

export const QUERY = {
  NEWS: 'News',
  /**
   * Employers & Benefits Plan
   */
  PLAN: 'Plan',
  FAQ: 'FAQ',
  /**
   * Medications
   */
  DRUGS: 'Drugs',
  PHARMACIES: 'Pharmacies',
  PROVINCES: 'Provinces',
  /**
   * Program Supporters
   */
  SUPPORTERS: 'Supporters',
  ABOUT: 'About',
  PRIVACY_POLICY: 'PrivacyPolicy',
  TERMS_OF_USE: 'TermsOfUse',
  LANDING_POPUP: 'LandingsPopup',
  HOMEPAGE_POPUP: 'HomePagePopups',
  REFERRAL_PARTNER: 'ReferralPartner',
  FEATURED_BRANDS: 'FeaturedBrands',
  CERT_POOL: 'CertPool',
} as const;
