import { generatePath, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ROUTES } from '../../router';

export function useClientSiteIndex() {
  const intl = useIntl();
  const { lang } = useParams();

  return [
    {
      url: generatePath(ROUTES.ABOUT, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageAbout.meta.title' }),
    },
    {
      url: generatePath(ROUTES.CONTACT, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageContactUs.meta.title' }),
    },
    {
      url: generatePath(ROUTES.PLAN, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageEmployers.meta.title' }),
    },
    {
      url: generatePath(ROUTES.FAQ, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageFAQ.meta.title' }),
    },
    {
      url: generatePath(ROUTES.HOME, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageHome.meta.title' }),
    },
    {
      url: generatePath(ROUTES.PHARMACY_LOCATOR, { lang: lang ?? 'en' }),
      label: intl.formatMessage({
        id: 'components.PagePharmacyLocator.meta.title',
      }),
    },
    {
      url: generatePath(ROUTES.COMPANY_REGISTRATION, { lang: lang ?? 'en' }),
      label: intl.formatMessage({
        id: 'components.PagePharmacyLocatorRegistration.meta.title',
      }),
    },
    {
      url: generatePath(ROUTES.PRIVACY_POLICY, { lang: lang ?? 'en' }),
      label: intl.formatMessage({
        id: 'components.PagePrivacyPolicy.meta.title',
      }),
    },
    {
      url: generatePath(ROUTES.TERMS_OF_USE, { lang: lang ?? 'en' }),
      label: intl.formatMessage({ id: 'components.PageTermsOfUse.meta.title' }),
    },
  ];
}
