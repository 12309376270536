import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { PageHome } from './pages/PageHome';
import { PageAbout } from './pages/PageAbout';
import { PageNews } from './pages/PageNews';
import { PageNewsDetails } from './pages/PageNewsDetails';
import { PageFAQ } from './pages/PageFAQ';
import { PageEmployers } from './pages/PageEmployers';
import { PageContactUs } from './pages/PageContactUs';
import { PagePrivacyPolicy } from './pages/PagePrivacyPolicy';
import { PageTermsOfUse } from './pages/PageTermsOfUse';
import { PageNotFound } from './pages/PageNotFound';
import { PagePharmacyLocator } from './pages/PagePharmacyLocator';
import { PagePharmacyLocatorRegistration } from './pages/PagePharmacyLocatorRegistration';
import { PagePharmacyLocatorSERP } from './pages/PagePharmacyLocatorSERP';
import { PageWizard } from './pages/PageWizard';
import { PageLanding } from './pages/PageLanding';
import { PageReferralPartner } from './pages/PageReferralPartner';
import { PageSitemap } from './pages/PageSitemap';
import { PageTestApi } from './pages/PageTestApi';

export const ROUTES = {
  HOME: '/:lang',
  ABOUT: '/:lang/about',
  NEWS_LIST: '/:lang/news',
  NEWS_DETAILS: '/:lang/news/:id',
  FAQ: '/:lang/faq',
  PLAN: '/:lang/employers',
  CONTACT: '/:lang/contact',
  PRIVACY_POLICY: '/:lang/privacy',
  TERMS_OF_USE: '/:lang/terms',
  TEST_API: '/:lang/test-api',
  WIZARD: '/:lang/wizard',
  PHARMACY_LOCATOR: '/:lang/pharmacy-locator',
  PHARMACY_LOCATOR_SERP: '/:lang/pharmacy-locator/serp',
  COMPANY_REGISTRATION: '/:lang/pharmacy-locator/registration',
  LANDINGS: '/:lang/l/:slug',
  REFERRAL_PARTNER: '/:lang/r/:slug',
  NOT_FOUND: '/404',
  SITEMAP: '/:lang/sitemap',
} as const;

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <PageHome />,
  },
  {
    path: '/',
    element: <Navigate to="/en" />,
  },
  {
    path: ROUTES.ABOUT,
    element: <PageAbout />,
  },
  {
    path: ROUTES.NEWS_LIST,
    element: <PageNews />,
  },
  {
    path: ROUTES.NEWS_DETAILS,
    element: <PageNewsDetails />,
  },
  {
    path: ROUTES.FAQ,
    element: <PageFAQ />,
  },
  {
    path: ROUTES.PLAN,
    element: <PageEmployers />,
  },
  {
    path: ROUTES.SITEMAP,
    element: <PageSitemap />,
  },
  {
    path: ROUTES.CONTACT,
    element: <PageContactUs />,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    element: <PagePrivacyPolicy />,
  },
  {
    path: ROUTES.TERMS_OF_USE,
    element: <PageTermsOfUse />,
  },
  {
    path: ROUTES.WIZARD,
    element: <PageWizard />,
  },
  {
    path: ROUTES.PHARMACY_LOCATOR,
    element: <PagePharmacyLocator />,
  },
  {
    path: ROUTES.PHARMACY_LOCATOR_SERP,
    element: <PagePharmacyLocatorSERP />,
  },
  {
    path: ROUTES.COMPANY_REGISTRATION,
    element: <PagePharmacyLocatorRegistration />,
  },
  {
    path: ROUTES.LANDINGS,
    element: <PageLanding />,
  },
  {
    path: ROUTES.REFERRAL_PARTNER,
    element: <PageReferralPartner />,
  },
  {
    path: ROUTES.TEST_API,
    element: <PageTestApi />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
