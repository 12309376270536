import { httpClient } from '../httpClient';
import { Medication, PositiveInteger } from '../types';

interface GetFeaturedBrandsParams {
  province?: string;
}

interface GetFeaturedBrandsInternalParams extends GetFeaturedBrandsParams {
  orderBy: `${string}:${'asc' | 'desc'}`;
  isDisplayOnPublicSite: boolean;
  page: PositiveInteger;
  pageSize: PositiveInteger;
}

interface GetFeaturedBrandsResponse {
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Medication[];
}

export function getFeaturedBrands(
  params: GetFeaturedBrandsParams,
  language: string,
) {
  return httpClient.get<
    GetFeaturedBrandsInternalParams,
    GetFeaturedBrandsResponse
  >('/products', {
    ...params,
    page: 1,
    pageSize: 10000,
    isDisplayOnPublicSite: true,
    orderBy: language === 'en' ? 'product:asc' : 'productFr:asc',
  });
}
