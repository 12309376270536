import React, { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import {
  Alert,
  Box,
  Container,
  Link,
  Pagination,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { GetNewsListParams, getNewsList } from '../../lib/api/getNewsList';
import { Loading } from '../../components/Loading';
import { l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { ROUTES } from '../../router';
import { QUERY } from '../../queryClient';
import type { HTML } from '../../lib/types';
import { PharmacyLocator } from '../../components/PharmacyLocator';

const PAGE_SIZE = 10;

/**
 * Clear HTML tags from `content` and return simple text string
 */
function getTextContent(content: HTML): string {
  const div = document.createElement('div');
  div.innerHTML = content;
  return div.textContent ?? '';
}

/* eslint-disable */
/**
 * Smartly truncate string to provided `max` length but trying to keep sentences completed.
 *
 * @param str input string to truncate
 * @param min minimum length to truncate
 * @param n preferred length of string
 * @param max the ultimate maximum length to truncate
 * @param useWordBoundary enable pretty truncate mode, that trying to find food place to break a string
 */
function truncateString(
  str: string,
  min: number,
  n: number,
  max: number,
  useWordBoundary: boolean,
) {
  // Taken from https://stackoverflow.com/a/53875125
  // p/s: Could be safely removed if you do not need pretty truncating
  if (str.length <= max) return str;
  if (useWordBoundary) {
    // Prefer to break after a dot:
    var i = str.indexOf('.', n) + 1; // Look forward
    if (i < min || i > max) i = str.slice(0, n).lastIndexOf('.') + 1; // ...or backward
    if (i >= min) return str.slice(0, i); // No ellipsis necessary
    // If dot-break is impossible, try word break:
    i = str.indexOf(' ', n); // Look forward
    if (i < min || i > max) i = str.slice(0, n).lastIndexOf(' '); // ...backward
    if (i >= min) n = i; // Found an acceptable position
  }
  return str.substring(0, n) + '…';
}
/* eslint-enable */

export function PageNews() {
  const intl = useIntl();
  const { lang } = useParams();
  const [locale] = useAppLocale();
  const [page, setPage] = useState(1);

  const queryParams: GetNewsListParams = {
    page,
    pageSize: PAGE_SIZE,
    orderBy: 'createDate:desc',
  };
  const { data, isLoading, error } = useQuery([QUERY.NEWS, queryParams], () =>
    getNewsList(queryParams),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageNews.meta.title',
            defaultMessage: "What's New",
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <>
              <Typography variant="h1">
                <FormattedMessage
                  id="components.PageNews.header"
                  defaultMessage="What's New"
                />
              </Typography>

              <Box sx={{ mb: 5 }} />

              {data?.items.map((item) => (
                <Box key={item.encId} sx={{ my: 5 }}>
                  <Typography variant="body1" color="text.secondary">
                    <FormattedDate
                      month="long"
                      year="numeric"
                      day="numeric"
                      value={item.startDate}
                    />
                  </Typography>
                  <Link
                    href={generatePath(ROUTES.NEWS_DETAILS, {
                      id: item.encId,
                      lang: lang ?? 'en',
                    })}
                  >
                    <Typography
                      variant="h2"
                      color="primary"
                      style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    >
                      {l10n({ en: item.title, fr: item.frTitle }, locale)}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body1"
                    style={{ paddingBottom: '0.5rem' }}
                  >
                    {l10n(
                      {
                        en: truncateString(
                          getTextContent(item.content),
                          200,
                          250,
                          270,
                          true,
                        ),
                        fr: truncateString(
                          getTextContent(item.frContent),
                          200,
                          250,
                          270,
                          true,
                        ),
                      },
                      locale,
                    )}
                  </Typography>
                </Box>
              ))}

              {(data?.totalPages ?? 1) > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    page={page}
                    count={data?.totalPages ?? 1}
                    onChange={(_, value) => setPage(value)}
                  />
                </Box>
              )}
            </>
          )}
        </Container>

        <PharmacyLocator />
      </DefaultLayout>
    </>
  );
}
