import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useAppLocale } from '../AppLocaleProvider';
import { Language } from '../../Language';

export function LanguageSelector() {
  const intl = useIntl();
  const { lang } = useParams();

  const [locale, setLocale] = useAppLocale();
  const location = useLocation();

  if (lang === 'fr' && locale === Language.English) {
    setLocale(Language.French);
  } else if (lang !== 'fr' && locale === Language.French) {
    setLocale(Language.English);
  }

  return (
    <>
      <IconButton
        title={intl.formatMessage({
          id: 'components.LanguageSelector.button.translate',
          defaultMessage: 'Toggle locale',
        })}
        aria-label={intl.formatMessage({
          id: 'components.LanguageSelector.button.translate',
          defaultMessage: 'Toggle locale',
        })}
        onClick={() => {
          setLocale(lang === 'en' ? Language.French : Language.English);
        }}
        href={
          lang === 'fr'
            ? location.pathname.replace('/fr', '/en')
            : location.pathname.replace('/en', '/fr')
        }
      >
        {locale === Language.English ? 'FR' : 'EN'}
      </IconButton>
    </>
  );
}
