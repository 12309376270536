import React, { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
}

const CARD_WIDTH = 570;
const CARD_HEIGHT = 359;

export type CardLayout = 'Top' | 'None' | 'Bottom' | 'Left';
interface Point {
  x: number;
  y: number;
}
const TEXT_POSITION: Record<
  Exclude<CardLayout, 'None'>,
  { cert: Point; group: Point }
> = {
  Top: {
    cert: { x: 110, y: 37 },
    group: { x: 325, y: 37 },
  },
  Bottom: {
    cert: { x: 110, y: 337 },
    group: { x: 325, y: 337 },
  },
  Left: {
    cert: { x: 10, y: 220 },
    group: { x: 10, y: 280 },
  },
};

interface RxHelpCardProps {
  /**
   * Image URL
   */
  imgUrl: string;

  /**
   * Top Image URL
   */
  topImgUrl?: string;

  /**
   * Top Image URL
   */
  isSmallCard?: boolean;

  /**
   * Cert №
   */
  cert: string;

  /**
   * Group №
   */
  group: string;

  /**
   * Font color
   */
  certificateTextColor: string;

  /**
   * Position of cert/group text
   */
  layout?: CardLayout;

  /**
   * Do not render text on card
   */
  noLabels?: boolean;
}

export function RxHelpCard({
  imgUrl,
  topImgUrl,
  cert,
  group,
  certificateTextColor = 'white',
  layout = 'Bottom',
  noLabels,
  isSmallCard = false,
}: RxHelpCardProps) {
  const [error, setError] = useState();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const isExtraSmall = useMediaQuery('(min-width:360px)');

  useLayoutEffect(() => {
    setError(undefined);

    async function render() {
      const img = await loadImage(imgUrl);
      const topImg = topImgUrl ? await loadImage(topImgUrl) : undefined;

      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, CARD_WIDTH, CARD_HEIGHT);
      ctx.drawImage(img, 0, 0, CARD_WIDTH, CARD_HEIGHT);

      // if (topImgUrl && topImg)
      // {
      //   ctx.drawImage(topImg, 285, 120, CARD_WIDTH, 240);
      // }

      if (noLabels !== true && layout !== 'None') {
        ctx.font = '21px sans-serif';
        ctx.fillStyle = certificateTextColor;
        if (cert) {
          if (layout === 'Left') {
            ctx.fillText(
              `${String(cert).padStart(8, '0')}`,
              TEXT_POSITION[layout].cert.x,
              TEXT_POSITION[layout].cert.y,
            );
          } else {
            ctx.fillText(
              `CERT. ${String(cert).padStart(8, '0')}`,
              TEXT_POSITION[layout].cert.x,
              TEXT_POSITION[layout].cert.y,
            );
          }
        }
        if (group) {
          if (layout === 'Left') {
            ctx.fillText(
              `${group}`,
              TEXT_POSITION[layout].group.x,
              TEXT_POSITION[layout].group.y,
            );
          } else {
            ctx.fillText(
              `GROUP ${group}`,
              TEXT_POSITION[layout].group.x,
              TEXT_POSITION[layout].group.y,
            );
          }
        }
      }
    }

    render().catch((err) => setError(err));
  }, [
    imgUrl,
    cert,
    group,
    layout,
    certificateTextColor,
    noLabels,
    topImgUrl,
    isSmallCard,
  ]);

  if (error) {
    const certFormatted = String(cert).padStart(8, '0');
    return (
      <Box
        sx={{
          width: CARD_WIDTH,
          height: CARD_HEIGHT,
          maxWidth: '100%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 3,
          borderColor: (t) => t.palette.divider,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            py: 4,
            px: 2,
            mt: 4,
            backgroundColor: (t) => t.palette.grey[300],
          }}
        >
          <FormattedMessage
            id="components.RxHelpCard.error"
            defaultMessage="Cannot Load Image"
          />
        </Typography>

        <Typography
          variant="body1"
          sx={{
            py: 2,
            px: 2,
            mt: 4,
            backgroundColor: (t) => t.palette.grey[300],
          }}
        >
          <FormattedMessage
            id="components.RxHelpCard.error.cert"
            defaultMessage="Card Cert: {certFormatted}"
            values={{ certFormatted }}
          />
        </Typography>
        <Typography
          variant="body1"
          sx={{
            py: 1,
            px: 2,
            backgroundColor: (t) => t.palette.grey[300],
          }}
        >
          <FormattedMessage
            id="components.RxHelpCard.error.group"
            defaultMessage="Card Group: {group}"
            values={{ group }}
          />
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {topImgUrl && (
        <Box
          sx={{
            justifyContent: 'right',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isSmallCard && (
            <Box
              sx={{
                content: `url(${topImgUrl})`,
                position: 'absolute',
                zIndex: 1,
                transform: 'scale(0.5) translateX(50%)',
                paddingRight: '30px',
              }}
            />
          )}
          {!isSmallCard && (
            <Box
              sx={{
                content: `url(${topImgUrl})`,
                position: 'absolute',
                width: '239px',
                height: '74px',
                objectFit: 'contain',
                zIndex: 1,
                paddingRight: '60px',
              }}
            />
          )}
          <canvas
            ref={canvasRef}
            width={CARD_WIDTH}
            height={CARD_HEIGHT}
            style={
              // eslint-disable-next-line no-nested-ternary
              !isExtraSmall
                ? { maxWidth: '220px' }
                : !isDesktop
                ? { maxWidth: '300px' }
                : {}
            }
          />
        </Box>
      )}

      {!topImgUrl && (
        <canvas
          ref={canvasRef}
          width={CARD_WIDTH}
          height={CARD_HEIGHT}
          style={
            // eslint-disable-next-line no-nested-ternary
            !isExtraSmall
              ? { maxWidth: '220px' }
              : !isDesktop
              ? { maxWidth: '300px' }
              : {}
          }
        />
      )}
    </>
  );
}
