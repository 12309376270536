import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../router';

export function Footer() {
  const { lang } = useParams();

  return (
    <Box
      sx={{
        px: 4,
        py: 4,
        backgroundColor: (t) => t.palette.grey[800],
        color: 'white',
      }}
    >
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Typography variant="h2">
          <FormattedMessage
            id="components.Footer.title"
            defaultMessage="1-866-RxHelp4"
          />
        </Typography>

        <Box sx={{ mb: 2 }} />

        <Typography variant="body1">
          <FormattedMessage
            id="components.Footer.info.line1"
            defaultMessage="Cameron Stewart LifeScience Canada Inc."
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="components.Footer.info.line2"
            defaultMessage="3470 Superior Court"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="components.Footer.info.line3"
            defaultMessage="Oakville, ON  L6L 0C4"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="components.Footer.info.line4"
            defaultMessage="Phone: 416-674-0803"
          />
        </Typography>

        <Box sx={{ mb: 2 }} />

        <Stack
          spacing={{ xs: 0, sm: 1 }}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link
            sx={{ color: 'primary.light' }}
            href={generatePath(ROUTES.ABOUT, { lang: lang ?? 'en' })}
          >
            <FormattedMessage
              id="components.Footer.link.about"
              defaultMessage="About Us"
            />
          </Link>
          <Divider
            sx={{ borderColor: 'primary.light', height: { xs: 0, sm: 15 } }}
            orientation="vertical"
          />
          <Link
            sx={{ color: 'primary.light' }}
            href={generatePath(ROUTES.PRIVACY_POLICY, { lang: lang ?? 'en' })}
          >
            <FormattedMessage
              id="components.Footer.link.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </Link>
          <Divider
            sx={{ borderColor: 'primary.light', height: { xs: 0, sm: 15 } }}
            orientation="vertical"
          />
          <Link
            sx={{ color: 'primary.light' }}
            href={generatePath(ROUTES.TERMS_OF_USE, { lang: lang ?? 'en' })}
          >
            <FormattedMessage
              id="components.Footer.link.termsOfUse"
              defaultMessage="Terms of Use"
            />
          </Link>
          <Divider
            sx={{ borderColor: 'primary.light', height: { xs: 0, sm: 15 } }}
            orientation="vertical"
          />
          <Link
            sx={{ color: 'primary.light' }}
            href={generatePath(ROUTES.SITEMAP, { lang: lang ?? 'en' })}
          >
            <FormattedMessage
              id="components.Footer.link.sitemap"
              defaultMessage="Sitemap"
            />
          </Link>
        </Stack>

        <Box sx={{ mb: 2 }} />

        <Typography variant="body2">
          <FormattedMessage
            id="components.Footer.copyright"
            defaultMessage="©2023 RxHelp. All Rights Reserved."
          />
        </Typography>
      </Container>
    </Box>
  );
}
