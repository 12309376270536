import { httpClient } from '../httpClient';
import type { DateTime, HTML } from '../types';

interface GetAboutInfoResponse {
  encId: string;
  details: HTML;
  frDetails: HTML;
  slug: string;
  createDate: DateTime;
  modifiedDate: DateTime;
}

const SLUG = 'about-us';

export function getAboutInfo() {
  return httpClient.get<void, GetAboutInfoResponse>(`/contents?slug=${SLUG}`);
}
